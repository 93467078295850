import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import {Link} from 'gatsby'

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { APP_URI } from '../configs';

import ilustration1 from '../images/ilustration-about-1.png'
import ilustration2 from '../images/ilustration-1.png'
import ilustration3 from '../images/ilustration-about-3.png'
import ilustration4 from '../images/ilustration-about-4.png'
import ilustration5 from '../images/ilustration-3.png'

import '../scss/how-it-works.scss'

export default class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    new WOW.WOW({
        live: false,
        offset: 500
    }).init();
  }

  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }


  render(){
    return(
    <div className="how-it-works">
            <div className="content how-it-works-content">
      <Topbar handlerNavigation={(ref) => this.scrollToReference(ref)} pageReferences={this.references}/>
      <Helmet>

      </Helmet>
      {/*
      <WhatsappButton/>
      */}
      <section ref={this.references.hero} className="hero--section">
        <div className="wrapper">
          <div className="flex--container">
            <h1 className="hero--title">Un lugar para crecer como gamer.</h1>
            <p className="hero--text">
            Troop es una plataforma creada especialmente para aquellas personas que disfrutan jugar, competir y divertirse. Sabemos lo difícil que puede ser lograr mejorar y disfrutar al máximo tu experiencia de juego. Por eso, pensamos este lugar, para que encuentres personas que disfrutan de jugar tanto como vos.
            </p>
          </div>
        </div>
      </section>

      <section ref={this.references.features} className="features--section">
        <div className="wrapper">
        <div className="flex--container wow fadeIn" data-wow-offset="200">
        <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--feature">
              <h3 className="feature--title">Formá tu Troop</h3>
              <p className="feature--text">
              Los Troops son equipos de gamers que se agruparon para poder mejorar en juegos donde el trabajo en equipo es fundamental para poder competir y ganar.  
              <br/><br/>
              Podés formar un Troop con tus amigos, o podés conocer nuevos compañeros de equipo utilizando nuestro sistema de búsqueda personalizado para formar o unirte a un equipo perfecto para vos.
              </p>
            </div>
            <div className="div--img">
              <img src={ilustration1} alt="implementación precisa de estrategias"></img>
            </div>
          </div>
          </div>
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--img left">
              <img src={ilustration2} alt="técnicas de mediación"></img>
            </div>
            <div className="div--feature">
              <h3 className="feature--title">Mostrale a todos quién sos</h3>
              <p className="feature--text">
              En TROOP queremos que puedas ver tu crecimiento, pero que otros puedan verlo también. Por eso pensamos en un perfil de gamer, donde vas a poder vincular las cuentas de tus juegos para que figuren tus estadísticas reales, mostrar tus insignias y emblemas especiales, demostrar tus habilidades y compartir tus mejores momentos y logros.
              </p>
            </div>
          </div>
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--feature">
              <h3 className="feature--title">Ganá insignias y emblemas</h3>
              <p className="feature--text">
              Progresá en tus juegos y completa los desafíos que tenemos para vos. Al vincular tus juegos con TROOP, todos tus datos y estadísticas se usarán para validar los desafíos y que puedas conseguir tus insignias. Cada vez que completes un desafío, obtendrás una insignia y puntos que te van a servir para subir el rango de tu perfil.
              <br/><br/>
              A medida que vayas subiendo de rango, vas a conseguir emblemas para mostrarle a todos tu progreso. 
              </p>
            </div>
            <div className="div--img">
              <img src={ilustration3} alt="estudio de cada deudor"></img>
            </div>
          </div>
          <div className="flex--container wow fadeIn" data-wow-offset="200">
            <div className="div--img left">
              <img src={ilustration4} alt="técnicas de mediación"></img>
            </div>
            <div className="div--feature">
              <h3 className="feature--title">Editá tus clips fácilmente</h3>
              <p className="feature--text">
              Sabemos que compartir tus mejores jugadas es importante para que sepan cómo jugas. Por eso pensamos un sistema que te permita subir tus clips para que los recortes y edites de una forma muy fácil desde tu navegador. No te preocupes si no sabes editar, creamos plantillas predeterminadas para que elijas los efectos y ediciones que más se adapten a tu clip.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref={this.references.logos} className="logos--section">
        <div className="wrapper wow fadeIn" data-wow-offset="200">
          <div className="logo--title">
            <h2 className="title--present">¿Qué esperás para unirte a TROOP?</h2>
            <p className="text--present">Creá tu cuenta en Troop y preparate para competir, 
mejorar y divertirte.</p>
          </div>    
          <Link to="/signup" className="button--global">Unite a Troop</Link>
          {/* <LogoSlider/> */}
        </div>       
      </section>
      <Footer/>
    </div>
    </div>
    );
  }
}
